<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense class="d-flex flex-md-row flex-column align-center">
            <v-col
              md="6"
              cols="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("multistop_quoted") }}</h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{ $t("multistop_quoted_tooltip") }}
                    <!-- </li> -->
                    <!-- </ul> -->
                    <!-- test -->
                  </span>
                </v-tooltip>
              </span>
            </v-col>
            <v-col
              md="6"
              cols="12"
              class="d-flex flex-row justify-md-end justify-center"
            >
              <span class="">
                <v-btn
                  class=""
                  rounded
                  color="success"
                  @click.stop="handleClick()"
                >
                  <span class="d-flex flex-row justify-center align-center">
                    <v-icon style="color: #ffffff !important">
                      {{ icons.mdiMapMarkerPath }}</v-icon
                    >
                    <span class="ml-2">
                      {{ $t("multi_stop_new") }}
                    </span>
                  </span>
                </v-btn>
              </span>
            </v-col>
          </v-row>
          <br />
          <v-row
            dense
            class="d-flex flex-md-row flex-column align-center"
            v-if="table.items.length > 0"
          >
            <v-col cols="12">
              <DataTable
                :headers="table.headers"
                :items="table.items"
                :loading="table.loading"
                @cancelledMultiStopQuote="getMultiStopsQuotes()"
                @refresh="getMultiStopsQuotes()"
                @updateQuote="updateQuote($event)"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="table.items.length < 1 && !table.loading">
            <v-col
              cols="12"
              class="d-flex flex-column justify-center align-center"
            >
              <v-img
                src="@/views/Multistop/assets/placerLight.png"
                width="300"
                v-if="!$vuetify.theme.isDark"
              ></v-img>
              <v-img
                src="@/views/Multistop/assets/placerDark.png"
                width="300"
                v-else
              ></v-img>
            </v-col>
          </v-row>
          <v-row v-if="table.items.length === 0 && table.loading" dense>
            <v-col
              cols="12"
              class="d-flex flex-row justify-center align-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Component used only for rendering the view to download a quote -->
    <QuoteInvoice
      ref="invoiceComponent"
      style="display: none; position: absolute; right: 10000px"
      :quote="quote"
    />
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiMapMarkerPath,
} from "@mdi/js";
import axios from "@/plugins/axios";
import DataTable from "./parts/DataTable.vue";
import QuoteInvoice from "@/views/RunningTrips/components/Invoice/QuoteInvoice.vue";
import html2pdf from "html2pdf.js";
import { mapActions, mapState } from "vuex";

export default {
  components: { DataTable, QuoteInvoice },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
        mdiMapMarkerPath,
      },
      table: {
        headers: [
          {
            text: "ID",
            value: "unique_id",
            align: "start",
            sortable: false,
          },
          {
            text: "DATE AND TIME",
            value: "start_time",
            align: "start",
            sortable: false,
          },
          {
            text: "DESCRIPTION",
            value: "trip_comment",
            align: "center",
            sortable: false,
          },
          { text: "COST", value: "total", align: "end", sortable: false },
          {
            text: "SERVICIO",
            value: "serviceType",
            align: "center",
            sortable: false,
          },
          {
            text: "ORIGIN ADDRESS",
            value: "source_address",
            align: "start",
            sortable: false,
          },
          {
            text: "LAST STOP",
            value: "destination_address",
            align: "start",
            sortable: false,
          },
          {
            text: "STOPS",
            value: "stop_quantity",
            align: "end",
            sortable: false,
          },
          {
            text: "STATUS",
            value: "quote_status",
            align: "center",
            sortable: false,
          },
          {
            text: "ACTION",
            value: "actions",
            sortable: false,
            align: "center",
          },
          { text: "", value: "data-table-expand" },
        ],
        items: [],
        loading: false,
      },
      quote: {},
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleClick() {
      this.$router.push({ name: "multiStopNew" });
    },
    async getMultiStopsQuotes() {
      this.table.loading = true;
      this.table.items = [];

      let params = {
        limit: 1000,
        sort_item: ["created_at", -1],
        quote_status: 0,
      };
      try {
        const { data } = await axios.get("/quotes", { params: params });
        if (!!data) {
          data.quotes.forEach((quote) => {
            this.table.items.push(quote);
          });
        } else {
          throw new Error("Error in /quotes");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.table.loading = false;
    },
    updateQuote(quote) {
      this.quote = quote;
    },
    downloadInvoice() {
      const isDarkMode = this.$vuetify.theme.dark;
      const invoiceComponent = this.$refs.invoiceComponent;
      this.setLoading(true);
      try {
        if (isDarkMode) {
          this.$vuetify.theme.dark = false;
        }

        setTimeout(async () => {
          const element = invoiceComponent.$refs.carInvoice;
          element.className = "col-12 fontUrbanist";
          const images = element.querySelectorAll("img");
          for (const img of images) {
            if (!img.src.startsWith("https://riderys3bucket.s3")) {
              continue;
            }
            try {
              const response = await axios.get(
                `${process.env.VUE_APP_STAGING}/corporate/proxy?url=${img.src}`,
                {
                  responseType: "blob",
                }
              );
              const blob = response.data;
              const reader = new FileReader();
              reader.onloadend = () => {
                img.src = reader.result;
              };
              reader.readAsDataURL(blob);
            } catch (error) {
              console.error("Error fetching image:", error);
            }
          }

          // Wait for all images to be converted to base64
          await new Promise((resolve) => setTimeout(resolve, 1000));

          const opt = {
            margin: 5,
            filename: `Ridery Corporate Panel - ${this.$t(
              "Service Quote"
            )}.pdf`,
            image: { type: "png", quality: 1 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          invoiceComponent.$el.style.display = "block";
          html2pdf()
            .set(opt)
            .from(element)
            .save()
            .finally(() => {
              this.setLoading(false);
              if (isDarkMode) {
                this.$vuetify.theme.dark = true;
              }
            });
        }, 10);
      } catch (error) {
        this.setLoading(false);
        if (isDarkMode) {
          this.$vuetify.theme.dark = true;
        }
      } finally {
        invoiceComponent.$el.style.display = "none";
      }
    },
  },
  created() {
    this.getMultiStopsQuotes();
  },
  watch: {
    quote: {
      handler: function (val) {
        this.downloadInvoice();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-white {
  color: #ffffff !important;
  max-width: 30px !important;
}
</style>
